<template>
  <el-dialog
    :title="!dataForm.id ? '【新增】客户信息' : '【修改】客户信息'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="80px" size="small">
      <el-form-item label="客户编号" prop="companyNo">
        <el-input v-model="dataForm.customerCode" readonly="readonly" placeholder="自动生成"></el-input>
      </el-form-item>
      <el-form-item label="客户全称" prop="fullName">
        <el-input v-model="dataForm.fullName" placeholder="客户全称"></el-input>
      </el-form-item>
      <el-form-item label="客户简称" prop="simpleName">
        <el-input v-model="dataForm.simpleName" placeholder="客户简称"></el-input>
      </el-form-item>
      <el-form-item label="联系人" prop="contactName">
        <el-input v-model="dataForm.contactName" placeholder="联系人"></el-input>
      </el-form-item>
      <el-form-item label="联系电话" prop="contactPhone">
        <el-input v-model="dataForm.contactPhone" placeholder="可输入手机号码或固定电话"></el-input>
      </el-form-item>
      <el-form-item label="地址" prop="address">
        <el-input v-model="dataForm.address" placeholder="地址"></el-input>
      </el-form-item>
      <el-form-item label="结算类型" prop="settleType">
        <el-select class="item-choose" v-model="dataForm.settleType" size="small" filterable clearable
                   placeholder="请选择">
          <el-option
            v-for="item in dictTypeMap.settle_type"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input type="textarea" :rows="5" v-model="dataForm.remark" placeholder="备注"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isPhone, isMobile } from '@/utils/validate'
export default {
  data () {
    let validateMobile = (rule, value, callback) => {
      if (!isMobile(value) && !isPhone(value)) {
        callback(new Error('格式错误，可输入手机号码或固定电话'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      dataForm: {
        id: null,
        customerCode: null,
        fullName: null,
        simpleName: null,
        contactName: null,
        contactPhone: null,
        address: null,
        settleType: null,
        remark: null,
        status: 1
      },
      dataRule: {
        fullName: [
          { required: true, message: '客户全称不能为空', trigger: 'blur' }
        ],
        simpleName: [
          { required: true, message: '客户简称不能为空', trigger: 'blur' }
        ],
        contactName: [
          { required: true, message: '联系人不能为空', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '联系电话不能为空', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '地址不能为空', trigger: 'blur' }
        ],
        settleType: [
          { required: true, message: '请选择结算类型', trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    }
  },
  methods: {
    init (id) {
      this.dataForm.id = id
      this.visible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
      })
      if (this.dataForm.id) {
        this.$http({
          url: this.$http.adornUrl(`/customer/${this.dataForm.id}`),
          method: 'get',
          params: this.$http.adornParams()
        }).then(({data}) => {
          if (data) {
            this.dataForm.customerCode = data.customerCode
            this.dataForm.fullName = data.fullName
            this.dataForm.simpleName = data.simpleName
            this.dataForm.address = data.address
            this.dataForm.contactName = data.contactName
            this.dataForm.contactPhone = data.contactPhone
            this.dataForm.address = data.address
            this.dataForm.settleType = data.settleType
            this.dataForm.remark = data.remark
          }
        })
      }
    },
    // 表单提交
    dataFormSubmit () {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          let methodName = !this.dataForm.id ? 'add' : 'update'
          let method = !this.dataForm.id ? 'put' : 'post'
          this.$http({
            url: this.$http.adornUrl(`/customer/` + methodName),
            method: method,
            data: this.$http.adornData({
              'id': this.dataForm.id || undefined,
              'fullName': this.dataForm.fullName,
              'simpleName': this.dataForm.simpleName,
              'address': this.dataForm.address,
              'contactName': this.dataForm.contactName,
              'contactPhone': this.dataForm.contactPhone,
              'settleType': this.dataForm.settleType,
              'remark': this.dataForm.remark,
              'status': this.dataForm.status
            })
          }).then(({data}) => {
            if (data) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.visible = false
                  this.$emit('refreshDataList')
                }
              })
            } else {
              this.$message.error('操作失败')
            }
          })
        }
      })
    }
  }
}
</script>
